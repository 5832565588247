import {Alert, Box, Button, Card, CardContent, CardHeader, Grid, Snackbar} from "@mui/material";
import {useSelector} from "../../../redux/store";
import {useEffect, useState} from "react";
import {DateCalendar} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import logbookApi from "../../../api/staffApi/logbookApi";
import TextField from "@mui/material/TextField";
import hasPermission from "../../../redux/hasPermission";
import floatParser from "../../../helpers/floatParser";
import Giderler from "./Giderler";
import Transferler from "./Transferler";
import Mesailer from "./Mesailer";
import Cariler from "./Cariler";
import PersonelOdemeleri from "./PersonelOdemeleri";

export interface gunsonuKalemModel {
	aciklama?: string;
	miktar: number;
	tur: number;
	kaynak?: number;
	hedef?: number;
}

export interface mesaiModel {
	calisan: number;
	ekstra?: boolean;
	gun?: number;
	saat?: number;
}

const Hesap = () => {
	const sabitler = useSelector(state => state.app.sabitler);
	const [tarih, setTarih] = useState<string>(dayjs(new Date(Date.now() - 5 * (60 * 60 * 1000))).format("YYYY-MM-DD"))
	const [kasa, setKasa] = useState<number>(0)
	const [kasaGunsonu, setKasaGunsonu] = useState<number>(0)
	const [nakitSatis, setNakitSatis] = useState<gunsonuKalemModel>()
	const [krediSatis, setKrediSatis] = useState<gunsonuKalemModel>()
	const [giderler, setGiderler] = useState<gunsonuKalemModel[]>([])
	const [cariler, setCariler] = useState<gunsonuKalemModel[]>([])
	const [transferler, setTransferler] = useState<gunsonuKalemModel[]>([])
	const [personelOdemeleri, setPersonelOdemeleri] = useState<gunsonuKalemModel[]>([])
	const [mesailer, setMesailer] = useState<mesaiModel[]>([])
	const gecmisHesapDuzenle = hasPermission("galleon_logbook.gunsonuHesaplariDuzenle")
	const paraTransferleriDuzenle = hasPermission("galleon_logbook.paraTransferleriDuzenle")


	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState('');
	const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
	const snackbarDuration = snackbarSeverity === 'error' ? 10000 : 4000;

	const cariHesapKod = sabitler?.find(x => x.tur === "HESAP" && x.tip === "CARI")?.id ?? 0;
	const cariKod = sabitler?.find(x => x.tur === "GUNSONU" && x.tip === "CARI")?.id ?? 0;
	const kasaKod = sabitler?.find(x => x.tur === "HESAP" && x.tip === "NAKIT")?.id ?? 0;
	const kasaHesap = sabitler?.find(x => x.tur === "HESAP" && x.tip === "NAKIT") ?? {isim: "", id: 0};
	const bankaKod = sabitler?.find(x => x.tur === "HESAP" && x.tip === "KREDI")?.id ?? 0;
	const bankaHesap = sabitler?.find(x => x.tur === "HESAP" && x.tip === "KREDI") ?? {isim: "", id: 0};
	const transferKod = sabitler?.find(x => x.tur === "GUNSONU" && x.tip === "TRANSFER")?.id ?? 0;
	const nakitSatisKod = sabitler?.find(x => x.tur === "GUNSONU" && x.tip === "NAKIT")?.id ?? 0;
	const krediSatisKod = sabitler?.find(x => x.tur === "GUNSONU" && x.tip === "KREDI")?.id ?? 0;
	const personelGiderKod = sabitler?.find(x => x.tur === "GUNSONU" && x.tip === "GIDER" && x.isim === "Personel")?.id ?? 0;
	const giderTipleri = sabitler?.filter(x => x.tur === "GUNSONU" && x.tip === "GIDER") ?? [];
	const ortakHesaplar = sabitler?.filter(x => x.tur === "HESAP" && x.tip === "ORTAK") ?? [];
	const calisanHesaplar = sabitler?.filter(x => x.tur === "HESAP" && x.tip === "CALISAN") ?? [];
	const calisanlar = sabitler?.filter(x => x.tur === "CALISAN") ?? [];

	const gunsonuGetir = () => {
		if (!!tarih && !!sabitler) {
			logbookApi.getGunsonu(tarih).then((response: any) => {
				setKasa(floatParser(response.kasa));
				setKasaGunsonu(floatParser(response.kasa_gunsonu));
				setNakitSatis({
					tur: nakitSatisKod,
					hedef: kasaKod,
					miktar: response.kayitlar.find((x: gunsonuKalemModel) =>
						x.tur === nakitSatisKod
						&& x.hedef === kasaKod)?.miktar ?? 0
				})
				setKrediSatis({
					tur: krediSatisKod,
					hedef: bankaKod,
					miktar: response.kayitlar.find((x: gunsonuKalemModel) =>
						x.tur === krediSatisKod
						&& x.hedef === bankaKod)?.miktar ?? 0
				})
				setGiderler(
					response.kayitlar.filter((x: gunsonuKalemModel) =>
						giderTipleri.map(gider => gider.id).includes(x.tur) && !x.hedef)
				)
				setPersonelOdemeleri(
					response.kayitlar.filter((x: gunsonuKalemModel) =>
						x.tur === personelGiderKod && !!x.hedef)
				)
				setCariler(
					response.kayitlar.filter((x: gunsonuKalemModel) =>
						x.tur === cariKod
					))
				setTransferler(
					response.kayitlar.filter((x: gunsonuKalemModel) =>
						x.tur === transferKod
					))
				setMesailer(
					response.calisanlar ?? [])
			}).catch(e => console.warn(e))
		}
	}

	const gunsonuKaydet = () => {
		logbookApi.setGunsonu({
			tarih,
			kalemler: [nakitSatis, krediSatis, ...giderler, ...transferler, ...cariler, ...personelOdemeleri],
			calisanlar: mesailer
		}).then(() => {
			gunsonuGetir()
			setSnackbarMessage('İşlem başarılı');
			setSnackbarSeverity('success');
			setSnackbarOpen(true);

		}).catch(e => {
			console.warn(e)
			setSnackbarMessage('İşlem başarısız');
			setSnackbarSeverity('error');
			setSnackbarOpen(true);
		})
	}

	const handleCloseSnackbar = () => {
		setSnackbarOpen(false);
	};

	useEffect(gunsonuGetir, [tarih, sabitler]);
	useEffect(() => {
		console.log("here")
		if (!!sabitler) {
			let nakitSatisSon = kasaGunsonu
				- kasa;
			giderler.forEach(gider => {
				if (gider.kaynak === kasaKod) {
					nakitSatisSon += Number(gider.miktar)
				}
			});
			personelOdemeleri.forEach(gider => {
				if (gider.kaynak === kasaKod) {
					nakitSatisSon += Number(gider.miktar)
				}
			});
			transferler.forEach(transfer => {
				if (transfer.kaynak === kasaKod) {
					nakitSatisSon += Number(transfer.miktar)
				}
				if (transfer.hedef === kasaKod) {
					nakitSatisSon -= Number(transfer.miktar)
				}
			})
			nakitSatis && setNakitSatis({
				...nakitSatis,
				miktar: nakitSatisSon
			})
		}
	}, [kasaGunsonu, giderler, transferler, personelOdemeleri])

	useEffect(() => {
		if (!!sabitler) {
			setNakitSatis({
				miktar: 0,
				tur: nakitSatisKod,
				hedef: kasaKod,
			})
			setKrediSatis({
				miktar: 0,
				tur: krediSatisKod,
				hedef: bankaKod,
			})
		}
	}, [sabitler]);

	return sabitler &&
      <Box height={"100%"}>

          <Snackbar
              open={snackbarOpen}
              autoHideDuration={snackbarDuration}
              onClose={handleCloseSnackbar}
              anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          >
              <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{width: '100%'}}>
								{snackbarMessage}
              </Alert>
          </Snackbar>
          <Box
              height={'calc(100% - 3rem)'}
              sx={{
								overflowY: "scroll",
								overflowX: "hidden",
								paddingX: {xs: 0, md: "1rem"},
								paddingBottom: "1rem",
							}}
          >
              <Grid container spacing={{xs: 1, md: 4}}>
                  <Grid item xs={6}>
                      <Card sx={{backgroundColor: "#ccc"}}>
                          <CardHeader title={"Satışlar"}/>
                          <CardContent>
                              <Grid container spacing={2}>
                                  <Grid item xs={12}>
																		{nakitSatis &&
                                        <TextField
                                            fullWidth
                                            disabled
                                            label={"Nakit Satış"}
                                            type={"number"}
                                            value={nakitSatis?.miktar}
                                            onChange={(event) => setNakitSatis({
																							...nakitSatis,
																							miktar: floatParser(event.target.value, nakitSatis.miktar)
																						})}
                                        />
																		}
                                  </Grid>
                                  <Grid item xs={12}>
																		{nakitSatis &&
                                        <TextField
                                            fullWidth
                                            type={"number"}
                                            label={"Kasa"}
                                            value={kasaGunsonu}
                                            onChange={(event) => {
																							setKasaGunsonu(floatParser(event.target.value, kasaGunsonu))
																						}}
                                        />
																		}
                                  </Grid>
                                  <Grid item xs={12}>
																		{krediSatis &&
                                        <TextField
                                            fullWidth
                                            type={"number"}
                                            label={"Kredi Satış"}
                                            value={krediSatis?.miktar}
                                            onChange={(event) => setKrediSatis({
																							...krediSatis,
																							miktar: floatParser(event.target.value, krediSatis?.miktar)
																						})}
                                        />
																		}
                                  </Grid>
                              </Grid>
                          </CardContent>
                      </Card>
                  </Grid>
                  <Grid item xs={6} justifyContent={"center"}>
                      <DateCalendar
                          value={dayjs(tarih)}
                          onChange={
														(newValue) => setTarih(newValue.format("YYYY-MM-DD"))
													}
                          maxDate={dayjs(new Date())}
                          minDate={gecmisHesapDuzenle ? undefined : dayjs(new Date())}
                      />
                  </Grid>
                  <Grid item xs={12}>
                      <Giderler
                          giderler={giderler}
                          setGiderler={setGiderler}
                          giderTipleri={giderTipleri}
                          kasaHesap={kasaHesap}
                          bankaHesap={bankaHesap}
                          ortakHesaplar={ortakHesaplar}
                          sabitler={sabitler}
                      />
                  </Grid>
								{paraTransferleriDuzenle &&
                    <Grid item xs={12}>
                        <Transferler
                            transferler={transferler}
                            setTransferler={setTransferler}
                            transferKod={transferKod}
                            giderTipleri={giderTipleri}
                            kasaHesap={kasaHesap}
                            bankaHesap={bankaHesap}
                            ortakHesaplar={ortakHesaplar}
                            calisanHesaplar={calisanHesaplar}
                            sabitler={sabitler}
                        />
                    </Grid>}
								{paraTransferleriDuzenle &&
                    <Grid item xs={12}>
                        <PersonelOdemeleri
                            personelOdemeleri={personelOdemeleri}
                            setPersonelOdemeleri={setPersonelOdemeleri}
                            personelGiderTuru={personelGiderKod}
                            kasaHesap={kasaHesap}
                            bankaHesap={bankaHesap}
                            ortakHesaplar={ortakHesaplar}
                            calisanHesaplar={calisanHesaplar}
                            sabitler={sabitler}
                        />
                    </Grid>}
                  <Grid item xs={12}>
                      <Mesailer
                          mesailer={mesailer}
                          setMesailer={setMesailer}
                          calisanlar={calisanlar}
                      />
                  </Grid>
                  <Grid item xs={12}>
                      <Cariler
                          cariKod={cariKod}
                          cariHesapKod={cariHesapKod}
                          cariler={cariler}
                          setCariler={setCariler}
                          calisanlar={calisanlar}
                      />
                  </Grid>
              </Grid>
          </Box>
          <Box display={"inline-flex"} width={"100%"} height={"3rem"} justifyContent={"center"} alignItems={"center"}>
              <Button onClick={gunsonuGetir} sx={{marginX: "1rem", minWidth: "10rem"}}>Yenile</Button>
              <Button onClick={gunsonuKaydet} sx={{marginX: "1rem", minWidth: "10rem"}}>Değişiklikleri Kaydet</Button>
          </Box>
      </Box>

}

export default Hesap;